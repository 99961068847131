<template>
  <div>
    <CasinosList />
    <section class="ads" v-html="$contentful.ads.Casino" />
  </div>
</template>

<script>
import CasinosList from '@/components/CasinosList'

export default {
  name: 'Casinos',
  components: { CasinosList }
}
</script>
